import { useEffect, useState } from 'react';
import { experienceProgramData } from 'src/assets/database/programData';
import {
  IExperienceProgram,
  IExperienceProgramData,
} from 'src/interfaces/user-web.interface';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export default function WebBookModal() {
  const [showModal, setShowModal] = useState(false);
  const [openBookList, setOpenBookList] = useState<IExperienceProgram[]>([]);

  useEffect(() => {
    const tmpList = [] as IExperienceProgram[];
    // 각 카테고리 내에서의 프로그램들 중 사전예약 링크가 열려있는 항목만 반환 (2차원 배열 순회)
    experienceProgramData.forEach((item: IExperienceProgramData) =>
      item.programList.forEach((item2: IExperienceProgram) =>
        item2.status === 'open' && item2.bookUrl ? tmpList.push(item2) : null,
      ),
    );
    if (tmpList.length > 0) setOpenBookList(tmpList);
  }, [experienceProgramData]);

  useEffect(() => {
    if (openBookList?.length > 0) setShowModal(true);
  }, [openBookList]);

  if (showModal)
    return (
      <ModalContainer>
        <ModalTitle>사전접수 안내</ModalTitle>
        <ModalExplanation>
          현재 사전 예약 진행 중인 프로그램 리스트입니다.
        </ModalExplanation>
        <ModalListBlock>
          <ModalListBoard>
            {openBookList?.map((item: IExperienceProgram) => (
              <ModalElement>
                <h1>{item?.name}</h1>
                <button
                  type="button"
                  onClick={() => window.open(item?.bookUrl)}
                >
                  사전 접수
                </button>
              </ModalElement>
            ))}
          </ModalListBoard>
          <CloseButton onClick={() => setShowModal(false)}>
            팝업 닫기
          </CloseButton>
        </ModalListBlock>
      </ModalContainer>
    );
  return null;
}

const ModalContainer = styled(FlexBox)`
  position: fixed;
  top: 10%;
  left: 5%;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  border-radius: 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.8);

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ModalTitle = styled.h1`
  margin-top: 12px;

  color: ${({ theme }) => theme.colors.fontColor};
  text-align: center;
  font-family: Cafe24Ohsquare;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
`;

const ModalExplanation = styled.h2`
  margin-bottom: 12px;

  color: #101010;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
`;

const ModalListBlock = styled(FlexBox)`
  width: 100%;
  padding: 16px 0;
  background-color: white;

  padding: 0px 20px 16px 20px;
  border-radius: 0px 0px 16px 16px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
`;

const ModalListBoard = styled(FlexBox)`
  width: 100%;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ModalElement = styled(FlexBox)`
  width: 100%;

  padding-top: 12px;
  padding-bottom: 12px;

  border-bottom: 1px solid #f0f2f9;

  justify-content: space-between;
  align-items: center;
  &:last-of-type {
    border-bottom: none;
  }
  > h1 {
    margin-right: 12px;

    color: #101010;
    font-family: Cafe24Ssurround;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
  }
  > button {
    border-radius: 8px;
    background: #f84851;

    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 18.2px */
    letter-spacing: -0.195px;

    display: inline-flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
`;

const CloseButton = styled.button`
  border-radius: 8px;
  background: #101010;

  color: #fff;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */
  letter-spacing: -0.225px;

  display: flex;
  width: 100%;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
