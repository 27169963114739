import { IMG_BUCKET_PC } from 'src/consts/user-web.const';

export const experienceProgramData = [
  {
    id: 1,
    categoryName: '경연대회',
    summary:
      '전통놀이로 한판 승부! 열정과 전통이 만나 최강자를 가리는 경연대회',
    icon: `${IMG_BUCKET_PC}ExProgramIcon1.webp`,
    image: `${IMG_BUCKET_PC}ExProgramChar1.webp`,
    color: '#8383B3',
    color2: '#D0D0EF',
    programList: [
      {
        id: 1,
        date: '3월 16일(일) 오전 11시',
        name: '민속놀이\n전국대회',
        explanation:
          '지역 주민과 방문객이 함께 어울려 경쟁하는 체험형 프로그램입니다. 참가자들은 제주 전통 민속놀이인 제주에 꽃이 피었습니다부터 듬돌들기까지 **총 7개의 경기에서 각 라운드에서 탈락하지 않도록 실력을 발휘**해야 합니다. 마지막 라운드까지 살아남은 **최종 참가자가 제주 민속놀이 챔피언으로 선정되며, 특별한 시상**이 주어집니다!',
        subject: '사전 접수자 480명',
        place: '새별오름 광장, 새별오름 일원',
        status: 'open',
        bookUrl:
          'https://docs.google.com/forms/d/e/1FAIpQLSd7FzbKFZDJKXGfcn6T4ZBQaaR29DtkmIujwEVZODmTTv5g3A/viewform',
      },
    ],
  },
  {
    id: 2,
    categoryName: '읍면동 대항\n민속놀이 경연대회',
    summary:
      '정겨운 제주만의 민속놀이로 한마음 한뜻이 되어 승부를 겨루는 읍면동 대항 프로그램',
    icon: `${IMG_BUCKET_PC}ExProgramIcon2.webp`,
    image: `${IMG_BUCKET_PC}ExProgramChar2.webp`,
    color: '#D3ACA7',
    color2: '#EBCECB',
    programList: [
      {
        id: 1,
        date: '예선: 3월 14일(금)\n결승: 3월 15일(토)',
        name: '줄다리기 대회',
        explanation:
          '줄다리기 대회는 양 팀이 밧줄을 잡고 반대 방향으로 당기며 힘을 겨루는 경기입니다. 남자 20명과 여자 10명이 팀을 이루어 3판 2선승제로 경기가 진행됩니다. 최강의 줄다리기 팀에 도전하세요!',
        subject: '제주시 각 읍면동 주민',
        place: '광장 및 놀이마당',
        status: 'none',
        bookUrl: '',
      },
      {
        id: 2,
        date: '3월 15일(토)',
        name: '넉둥베기 대회',
        explanation:
          '넉둥베기 대회는 제주도 전통 윷놀이의 일종으로, 나무 조각(넉둥)을 던져 점수를 얻는 경기입니다. 3인 1조로 팀을 이루어 경기가 진행됩니다.',
        subject: '제주시 각 읍면동 주민',
        place: '광장 및 놀이마당',
        status: 'none',
        bookUrl: '',
      },
      {
        id: 3,
        date: '3월 14일(금)',
        name: '집줄놓기 대회',
        explanation:
          '집줄놓기 대회는 대나무와 짚을 이용해 줄을 꼬아 엮는 제주 전통 기술을 겨루는 경기입니다. 한정된 시간 내에 가장 정교하고 튼튼한 집줄을 완성해보세요!',
        subject: '제주시 각 읍면동 주민',
        place: '광장 및 놀이마당',
        status: 'none',
        bookUrl: '',
      },
      {
        id: 4,
        date: '3월 15일(토)',
        name: '듬돌들기 대회',
        explanation:
          '듬돌들기는 제주 전통 노동을 기반으로 한 체력 경기로, 무거운 돌을 들어 지정된 거리까지 이동하는 방식으로 승부를 가립니다. 가장 힘이 쎈 듬돌들기 챔피언이 되어보세요.',
        subject: '제주시 각 읍면동 주민',
        place: '광장 및 놀이마당',
        status: 'none',
        bookUrl: '',
      },
    ],
  },
  {
    id: 3,
    categoryName: '전통 민속놀이 체험',
    summary: '제주의 옛 문화를 직접 느낄 수 있는 전통 민속놀이 체험 프로그램',
    icon: `${IMG_BUCKET_PC}ExProgramIcon3.webp`,
    image: `${IMG_BUCKET_PC}ExProgramChar3.webp`,
    color: '#D3CCA7',
    color2: '#EEE9D0',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) - 3월 16일(일)\n읍면동 경연시간에는 미운영',
        name: '집줄놓기 체험',
        explanation:
          '제주 전통 방식으로 짚을 꼬아 줄을 만드는 체험입니다. 직접 줄을 꼬아보고, 튼튼한 집줄을 완성하는 과정에서 제주 전통 기술을 배워 보세요!',
        subject: '도민, 관광객',
        place: '광장',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 2,
        date: '3월 14일(금) - 3월 16일(일)\n읍면동 경연시간에는 미운영',
        name: '듬돌들기 체험',
        explanation:
          '힘을 겨루는 제주도만의 전통 방식! 옛날 제주 농촌에서 사용했던 큰 돌(듬돌)을 들어 올려 가족, 친구들과 힘을 겨뤄보세요!',
        subject: '도민, 관광객',
        place: '광장',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 3,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '방쉬연 날리기\n체험',
        explanation:
          '방쉬연 날리기는 정월대보름에 나쁜 기운을 없애고, 좋은 기운을 불러들이기 위해 연을 날리는 제주 전통 문화입니다. 직접 연을 만들고, 이뤄지길 바라는 소원을 연에 적어 띄어보세요!',
        subject: '도민, 관광객',
        place: '소풍마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 4,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '지게발 걷기 체험',
        explanation:
          '옛 제주 사람들은 무거운 짐을 운반할 때 지게발을 사용하여 힘을 분산시키고 균형을 잡았습니다. 다양한 방식으로 진행되는 지게발 걷기 체험에 참여해보세요!',
        subject: '도민, 관광객',
        place: '소풍마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 5,
        date: '3월 14일(금) - 3월 15일(토)',
        name: '승마 체험',
        explanation:
          '제주에서 전해 내려오는 ‘말테우리’ 문화를 직접 경험할 수 있는 프로그램입니다. 제주마와 함께하며 자연스럽게 승마를 익히고, 색다른 체험을 즐겨보세요!',
        subject: '도민, 관광객',
        place: '마상마예공연장',
        status: 'close',
        bookUrl: '',
      },
    ],
  },
  {
    id: 4,
    categoryName: '불문화체험',
    summary:
      '나만의 LED 쥐불로 제주만의 특별한 문화를 경험할 수 있는 불문화 체험',
    icon: `${IMG_BUCKET_PC}ExProgramIcon4.webp`,
    image: `${IMG_BUCKET_PC}ExProgramChar4.webp`,
    color: '#A2CAA0',
    color2: '#CCEACB',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '창작 LED 쥐불 만들기 및 체험',
        explanation:
          '정월대보름의 전통 놀이인 쥐불놀이를 현대적으로 즐길 수 있는 체험이에요. LED 쥐불키트를 이용해 자신만의 창작 쥐불을 만들고 LED 불빛을 이용해 쥐불을 돌려보세요!',
        subject: '도민, 관광객',
        place: '불턱 광장',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 2,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '불턱 만들기 체험',
        explanation:
          '제주의 돌담은 강한 바람을 막고 농작물을 보호하기 위한 중요한 역할을 합니다. 불턱 만들기 체험을 통해 제주 전통 돌쌓기 기술을 배워 보세요!',
        subject: '도민, 관광객',
        place: '소풍마당',
        status: 'open',
        bookUrl:
          'https://docs.google.com/forms/d/e/1FAIpQLSeGN2I5bVN_9rxHpjb6tBJNVWEgHAsV_4t4BBeVilm0JhJk-g/viewform?usp=sharing',
      },
    ],
  },
  {
    id: 5,
    categoryName: '공예 체험',
    summary: '정성과 소원을 담아 나만의 특별한 작품을 만들 수 있는 공예 체험',
    icon: `${IMG_BUCKET_PC}ExProgramIcon5.webp`,
    image: `${IMG_BUCKET_PC}ExProgramChar5.webp`,
    color: '#99C8C9',
    color2: '#C9EBEC',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '희망 화분 만들기\n체험',
        explanation:
          '나만의 희망을 담은 화분을 만들어보는 체험입니다. 폐목재를 재활용해 화분을 직접 만들고, 작은 식물을 심으며 자연의 소중함을 배워 보세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 2,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '희망 풍경 만들기\n체험',
        explanation:
          '소망을 담아 직접 만든 풍경이 바람을 타고 맑은 소리를 냅니다. 전통적인 도기형 풍경에 희망을 적고 색을 입혀, 제주 바람과 함께 소원을 날려보세요.',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 3,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '들불 소원팔찌\n매듭 공예 체험',
        explanation:
          '전통 매듭 공예를 활용해 직접 만드는 들불 소원팔찌! 한 해의 소원을 담아 만든 팔찌를 손목에 걸고, 제주들불축제에서 행운을 기원해 보세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 4,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '액막이 걱정인형\n만들기 체험',
        explanation:
          '나의 고민을 들어주는 작은 친구, 걱정인형 만들기 체험! 직접 만든 인형에 나쁜 기운을 담아 액운을 막고, 편안한 밤을 보낼 수 있도록 나만의 걱정인형을 만들어 보세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 5,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '축제 기념뱃지\n만들기 체험',
        explanation:
          '제주들불축제의 기념을 남길 수 있는 나만의 뱃지를 만들어 보세요! 뱃지프레스를 이용해 직접 디자인하고 제작하여, 특별한 축제의 순간을 기념하세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
    ],
  },
  {
    id: 6,
    categoryName: '생태가치체험',
    summary: '제주의 오름에서 자연을 느낄 수 있는 트레킹 등의 생태가치체험',
    icon: `${IMG_BUCKET_PC}ExProgramIcon6.webp`,
    image: `${IMG_BUCKET_PC}ExProgramChar6.webp`,
    color: '#98A9C9',
    color2: '#C8D5EE',
    programList: [
      {
        id: 1,
        date: '3월 14일(금) - 3월 16일(일)\n매일 1회',
        name: "사운드스케이프 ‘오름의 소리'",
        explanation:
          '오름 전문가와 함께 자연의 소리를 따라 걸어보세요! 바람과 새소리가 들려주는 또 다른 제주를 만나볼 수 있습니다!',
        subject: '사전 접수 (회당 30명 모집)',
        place: '새별오름',
        status: 'open',
        bookUrl: 'https://url.kr/rvr8h2',
      },
      {
        id: 2,
        date: '3월 14일(금) - 3월 15일(토)\n매일 2회',
        name: '오름해설사와\n함께하는\n목장길 트레킹',
        explanation:
          '오름해설사와 함께 제주의 아름다운 목장길을 탐방하며 특별한 이야기를 들어보세요! 오름 전문가의 해설과 함께 제주 오름의 매력을 깊이 있게 경험할 수 있습니다!',
        subject: '사전 접수 (회당 30명 모집)',
        place: '새별오름, 이달봉',
        status: 'open',
        bookUrl: 'https://url.kr/rvr8h2',
      },
      {
        id: 3,
        date: '3월 14일(금), 15일(토)',
        name: '썬셋 트레킹',
        explanation:
          '붉게 물든 하늘에서 제주의 아름다움을 온전히 체험할 수 있는 오름 트레킹! 자연과 함께 걷는 특별한 순간을 경험해 보세요!',
        subject: '사전 접수 200명',
        place: '새별오름',
        status: 'open',
        bookUrl: 'https://url.kr/rvr8h2',
      },
      {
        id: 4,
        date: '3월 15일(토)',
        name: '오.미.자\n선발대회',
        explanation:
          '오름 체력왕을 찾습니다! 3월 15일(토) 하루동안 새별오름을 가장 많이 도신 한 분을 찾아 엄청난 상품을 드립니다.',
        subject: '사전 접수 50명',
        place: '새별오름 일원 및 오미자 운영부스',
        status: 'open',
        bookUrl: 'https://forms.gle/8Mr5QZmP2Ena3YJK6',
      },
      {
        id: 5,
        date: '3월 14일(금)',
        name: '새별오름 디지털 횃불등반',
        explanation:
          '디지털 횃불을 들고 밤하늘 아래 펼쳐지는 특별한 오름 등반! 제주 오름의 고요한 밤을 따라 걷으며 자연과 하나 되는 순간을 경험해 보세요. 별빛 아래 빛나는 트레킹으로 잊지 못할 추억을 만들어 보세요!',
        subject: '사전 접수 300명',
        place: '새별오름',
        status: 'open',
        bookUrl: 'https://url.kr/rvr8h2',
      },
      {
        id: 6,
        date: '3월 15일(토) 오후 4시',
        name: '제주그린벨\n(환경퀴즈대회)',
        explanation:
          '환경 상식도 쌓고, 제주 대표 환경 지식왕이 될 수 있는 제주그린벨 대회! 참가자들과 함께 환경 관련 퀴즈를 풀며 즐거운 시간을 보내고, 최후의 3인이 되어 특별한 상금을 노려보세요!',
        subject: '사전 접수 100명',
        place: '광장',
        status: 'open',
        bookUrl: 'https://forms.gle/HEysXfAxem69gS3N6',
      },
      {
        id: 7,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '탄소중립\n스탬프랠리',
        explanation:
          '축제장을 돌며 탄소중립 실천 미션을 수행하고, 스탬프를 모아보세요! 대중교통 이용, 친환경 오름 트레킹, 에코 캠페인 참여 등 다양한 탄소중립미션을 완료하면 친환경 기념품이 제공됩니다!',
        subject: '도민, 관광객',
        place: '새별오름 및 축제장 일원',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 8,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '업사이클링\n소품 만들기',
        explanation:
          '버려진 자원이 새로운 소품으로 변신하는 순간! 폐어망과 다양한 재활용 소재를 활용해 나만의 친환경 소품을 만들어 보세요!',
        subject: '도민, 관광객',
        place: '새별오름 및 축제장 일원',
        status: 'close',
        bookUrl: '',
      },
    ],
  },
  {
    id: 7,
    categoryName: '참여형 체험',
    summary: '함께 희망과 즐거움을 나누는 다양한 참여형 체험',
    icon: `${IMG_BUCKET_PC}ExProgramIcon7.webp`,
    image: `${IMG_BUCKET_PC}ExProgramChar7.webp`,
    color: '#86868E',
    color2: '#E4E4EA',
    programList: [
      {
        id: 1,
        date: '3월 16일(일) 10:00부터\n선착순 마감',
        name: '새봄, 새희망\n묘목 나눠주기',
        explanation:
          '제주들불축제에서 따뜻한 봄을 맞이하며 묘목을 나누어 드립니다. 소중한 나무 한 그루를 받아 직접 심어 보며, 자연과 함께 희망을 키워 가세요.',
        subject: '현장 대기(선착순)',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },

      {
        id: 2,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '들불축제\n방문인증 네컷',
        explanation:
          '제주들불축제에서만 찍을 수 있는 특별한 인생네컷! 들불축제 전용 프레임이 적용된 인생네컷을 촬영하고 추억을 간직해 보세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 3,
        date: '3월 14일(금) - 3월 15일(토)',
        name: '오름 희망 디지털\n엽서 보내기',
        explanation:
          '제주들불축제에서 전하는 따뜻한 메시지! 직접 촬영한 영상을 담은 QR 코드가 있는 엽서를 제작하여, 친구나 가족에게 추억을 선물할 수 있어요. 1개월 뒤 원하는 메일로 전송됩니다!',
        subject: '도민, 관광객',
        place: '새별우체국',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 4,
        date: '3월 14일(금) - 3월 16일(일)',
        name: '액막이 팥죽나눔',
        explanation:
          '한 해의 액운을 막는 제주 전통 팥죽을 무료로 제공하는 행사입니다. 축제의 따뜻한 분위기 속에서 이웃들과 함께 팥죽을 나눠 보세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 5,
        date: '3월 14일(금) - 3월 15일(토)',
        name: '부리부리를\n잡아라',
        explanation:
          '부리부리를 잡으면 선물이 따라옵니다! 축제장 곳곳에 등장하는 부리부리를 찾아 즉석 게임에 도전하고 기념품을 받아 가세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
      {
        id: 6,
        date: '3월 14일(금) - 3월 15일(토)',
        name: '종이비행기\n날리기',
        explanation:
          '종이비행기 멀리날리기, 특색있는 비행기 접기 등 다양한 종이 비행기 게임에 참가해 보세요!',
        subject: '도민, 관광객',
        place: '놀이마당',
        status: 'close',
        bookUrl: '',
      },
    ],
  },
];
