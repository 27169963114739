import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import styled from 'styled-components';

export const Map = styled.div`
  width: 100%;
  padding: 16px;
`;

export const Container = styled.div`
  padding: 16px 16px;
  margin-bottom: 20px;

  width: calc(100% - 32px); // 전체 너비에서 좌우 마진만큼 빼서 계산

  flex-shrink: 0;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 16px 20px -2px rgba(0, 0, 0, 0.12);

  position: absolute;
  bottom: 0;

  z-index: 11;
  cursor: pointer;
`;

export const InBox = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 92px); // 전체 너비에서 썸네일과 간격만큼 뺀 값
`;

export const Img = styled.img`
  width: 88px;
  height: 88px;
  flex-shrink: 0;
`;

export const Name = styled.div`
  color: #111;

  /* headline1 */
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px; /* 144.444% */

  margin-bottom: 2px;
`;

export const Time = styled.div`
  color: var(--Font-03_Gray, #505050);

  font-size: 15px;
  font-weight: 400;
  line-height: 22px; /* 146.667% */

  white-space: nowrap;
`;

export const Place = styled.div`
  color: #333;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  white-space: nowrap;
`;

export const Summary = styled.div`
  color: var(--Font-02_black, #111);
  font-size: 15px;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
  letter-spacing: -0.375px;

  margin-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
`;

export const LinkBox = styled.div`
  display: flex;
  margin: 12px auto;
  width: calc(100% + 8px);
  margin-left: -4px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 64px;
  background: var(--Button-Light_Color, #f7f7fb);

  padding: 10px 0;
`;

export const LinkImg = styled.img`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// 여기 폰트 수정
export const LinkName = styled.div`
  color: #111;
  text-align: center;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
