/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import FullInfo from 'src/components/user-mobile/map/click/full/FullInfo';
import FullMenu from 'src/components/user-mobile/map/click/full/FullMenu';
import Close from 'src/components/user-mobile/map/click/full/Close';

import styled from 'styled-components';
import FullBasic from 'src/components/user-mobile/map/click/full/FullBasic';
import { IGetBoothSingle } from 'src/interfaces/user-mobile/map.interface';

function MapFull({
  setIsFull,
  data,
}: {
  setIsFull: React.Dispatch<React.SetStateAction<boolean>>;
  data: IGetBoothSingle;
}) {
  const isMenusEmpty =
    !Array.isArray(data.menus) || (data.menus as any[]).length === 0;

  useEffect(() => {
    if (!data.content && isMenusEmpty) {
      console.log('🚨 No content or menus! Closing MapFull...');
      setIsFull(false);
    }
  }, [data, setIsFull, isMenusEmpty]);
  return (
    <Container>
      <FullInfo {...data} />
      <DivideLine />
      {Array.isArray(data.menus) && (data.menus as any[]).length > 0 ? (
        <FullMenu menus={data.menus as any[]} />
      ) : (
        <FullBasic content={data.content || ''} />
      )}

      <Close setIsFull={setIsFull} />
    </Container>
  );
}
export default MapFull;

const Container = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);

  flex-shrink: 0;

  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 16px 20px -2px rgba(0, 0, 0, 0.12);

  position: absolute;
  bottom: 0;

  overflow: scroll;

  z-index: 1400;
`;

const DivideLine = styled.div`
  width: 100%;
  height: 8px;
  flex-shrink: 0;
  background: var(--Button-Light_Color, #f7f7fb);
`;
