import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import { useEffect, useRef, useState } from 'react';
import { StyledViewer } from 'src/styles/OverviewStyle';
import getTranslateString from 'src/utils/getTranslateString';

function MarkdownViewer({ content }: { content: string }) {
  const refViewer = useRef<Viewer | null>(null);
  const [translatedText, setTranslatedText] = useState(content); // 변수명 변경

  useEffect(() => {
    function encodeImageUrls(markdownContent: string): string {
      return markdownContent.replace(
        /!\[.*?\]\((https?:\/\/.*?\.(?:png|jpg|jpeg|gif|webp))\)/g,
        (match, url) => {
          const encodedUrl = encodeURI(url); // URL 전체를 인코딩
          return match.replace(url, encodedUrl);
        },
      );
    }

    function extractImageUrls(markdownContent: string): {
      contentWithoutImages: string;
      images: { match: string; url: string }[];
    } {
      const imageRegex =
        /!\[.*?\]\((https?:\/\/.*?\.(?:png|jpg|jpeg|gif|webp))\)/g;
      const images = [];
      let matchResult = imageRegex.exec(markdownContent);
      let contentWithoutImages = markdownContent;

      while (matchResult !== null) {
        // 매칭된 결과가 유효한지 확인
        if (matchResult[0] && matchResult[1]) {
          images.push({ match: matchResult[0], url: matchResult[1] });
          contentWithoutImages = contentWithoutImages.replace(
            matchResult[0],
            `{{image${images.length - 1}}}`,
          );
        }

        // 다음 매칭 결과를 가져옴
        matchResult = imageRegex.exec(markdownContent);
      }

      return { contentWithoutImages, images };
    }

    function restoreImageUrls(
      translatedContent: string,
      images: { match: string; url: string }[],
    ): string {
      let restoredContent = translatedContent;
      images.forEach((image, index) => {
        restoredContent = restoredContent.replace(
          `{{image${index}}}`,
          image.match,
        );
      });
      return restoredContent;
    }

    const translateContent = async () => {
      const { contentWithoutImages, images } = extractImageUrls(content);
      const translated = await getTranslateString(contentWithoutImages); // 번역 함수 호출
      const restoredContent = restoreImageUrls(translated, images);
      setTranslatedText(restoredContent);
    };

    translateContent(); // 번역 수행

    const encodedContent = encodeImageUrls(translatedText); // 이미지 URL 인코딩

    if (refViewer.current) {
      refViewer.current.setMarkdown(encodedContent);
    } else {
      refViewer.current = new Viewer({
        el: document.querySelector('#viewer') as HTMLElement,
        initialValue: content,
        linkAttributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      });
    }

    return () => {
      if (refViewer.current) {
        refViewer.current.destroy();
        refViewer.current = null;
      }
    };
  }, [content, translatedText]);

  return <StyledViewer id="viewer" />;
}

export default MarkdownViewer;
