// import { TopFixedBar } from '../TopFixedBar';

import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';

import {
  BkBtn,
  TopFixedBar,
  TopFixedBlock,
} from 'src/styles/user-mobile/TopFixedBarStyle';
import styled from 'styled-components';

export default function TopFixedBarFestivalInfoOthers({
  categoryName,
}: {
  categoryName: string;
}) {
  const navigate = useNavigate();

  return (
    <TopFixedBlock>
      <StyledTopFixedBar>
        <Flex style={{ marginLeft: '16px' }}>
          <BkBtn src={bkBtn2} onClick={() => navigate(-1)} />
          <h1>{categoryName}</h1>
        </Flex>
        {/* <MenuBtn src={menuBtn3}/> */}
      </StyledTopFixedBar>
    </TopFixedBlock>
  );
}

const StyledTopFixedBar = styled(TopFixedBar)`
  position: relative;
  background-color: white;
`;
