import styled from 'styled-components';
import { FlexBox } from '../OverviewStyle';

export const ImageBoardWeb = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  > img {
    width: 100vw;
  }
`;

export const Shrinked2xImage = styled.img<{ width: number }>`
  width: ${props => props.width / 2}px;
  height: auto;
`;

export const InputBoxWeb = styled(FlexBox)`
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > h1 {
    margin-bottom: 8px;

    color: #111;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
  > input {
    width: 100%;
    height: 44px;

    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--Line-Regular_Color, #e5e5ec);

    color: var(--Font-03_Gray, #505050);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }
`;
