import { TopFixedBarBlankProgram } from 'src/styles/user-mobile/TopFixedBarStyle';

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Wrapper } from 'src/styles/user-mobile/OverviewStyle';
import { experienceProgramData } from 'src/assets/database/programData';
import {
  IExperienceProgram,
  IExperienceProgramData,
} from 'src/interfaces/user-web.interface';
import {
  BoardSetWidthProgram,
  Date,
  Explanation,
  ProgramBanner,
  ProgramButton,
  ProgramElement,
  ProgramExtraInfo,
  ProgramExtraInfoBox,
  ProgramListBoard,
  Title,
  VerticalLine,
} from 'src/styles/user-mobile/ProgramStyle';
import TopFixedBarProgramOthers from 'src/components/user-mobile/program/TopFixedBarProgramOthers';
import ProgramFooter from 'src/components/user-mobile/program/ProgramFooter';

export default function ProgramOthersInfo() {
  const currentIndex = Number(useParams().id) - 1;
  const data = experienceProgramData[currentIndex] as IExperienceProgramData;
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Wrapper>
      <TopFixedBarProgramOthers categoryName={data?.categoryName} />
      <BoardSetWidthProgram>
        <TopFixedBarBlankProgram />
        <ProgramBanner color={data?.color}>
          <h1>{data?.categoryName}</h1>
          <img alt="" src={data?.image} />
        </ProgramBanner>
        <ProgramListBoard>
          {data?.programList?.map((item: IExperienceProgram) => (
            <ProgramElement>
              <Date>{item?.date?.replace('\n', ' / ')}</Date>
              <Title>{item?.name}</Title>
              <Explanation>
                {item?.explanation
                  .split(/(\*\*.*?\*\*)/g)
                  .map((part, index) => {
                    if (part.startsWith('**') && part.endsWith('**')) {
                      return <b key={index}>{part.slice(2, -2)}</b>;
                    }
                    return part;
                  })}
              </Explanation>
              <ProgramExtraInfoBox>
                <ProgramExtraInfo>
                  <h1>대상</h1>
                  <VerticalLine />
                  <h2>{item?.subject}</h2>
                </ProgramExtraInfo>
                <ProgramExtraInfo>
                  <h1>장소</h1>
                  <VerticalLine />
                  <h2>{item?.place}</h2>
                </ProgramExtraInfo>
              </ProgramExtraInfoBox>
              {item?.status !== 'none' && (
                <ProgramButton
                  onClick={() =>
                    item?.bookUrl
                      ? window.open(item.bookUrl)
                      : alert('추후 업데이트 예정입니다.')
                  }
                  disabled={item?.status === 'close'}
                >
                  {item?.status === 'close' ? '현장 접수' : '사전 접수'}
                </ProgramButton>
              )}
            </ProgramElement>
          ))}
        </ProgramListBoard>
        <ProgramFooter />
      </BoardSetWidthProgram>
    </Wrapper>
  );
}
