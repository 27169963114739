import back from 'src/assets/images/user-web/Back.svg';
import styled from 'styled-components';

interface IProps {
  className?: string;
  onClickBackButton: React.MouseEventHandler<HTMLImageElement>;
}
WebBackButton.defaultProps = {
  className: '',
};

function WebBackButton({ onClickBackButton, className }: IProps) {
  return (
    <BackImage
      onClick={onClickBackButton}
      className={className}
      src={back}
      alt="뒤로가기 이미지"
    />
  );
}

export default WebBackButton;

const BackImage = styled.img`
  cursor: pointer;
`;
