import { useNavigate } from 'react-router-dom';

function onClickLink(
  event: React.MouseEvent<
    HTMLDivElement | HTMLImageElement | HTMLButtonElement
  >,
  navigate: ReturnType<typeof useNavigate>,
) {
  const URL = event.currentTarget.dataset.url;
  const HTTP = 'http://';
  const HTTPS = 'https://';
  if (URL) {
    if (URL.startsWith(HTTP) || URL.startsWith(HTTPS)) {
      // 상대경로만 추출하는 법
      return window.open(URL);
    }
    // 상대 경로만 있는 경우 navigate 실행
    return navigate(URL);
  }

  return alert('추후 업데이트 예정입니다.');
}

export default onClickLink;
