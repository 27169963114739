import styled, { useTheme } from 'styled-components';
import { TopFixedBarHome } from 'src/styles/user-mobile/TopFixedBarStyle';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';

import {
  IBanner,
  IDownWidgetInfo,
  IMainMenus,
  IMiddleBanner,
  IUpWidgetInfo,
  IWidgetInfo,
} from 'src/interfaces/user-mobile/main.interface';
import {
  bannerInfoBlank,
  downWidgetBlank,
  mainMenusBlank,
  middleBannerBlank,
  upWidgetInfoBlank,
  widgetInfoBlank,
} from 'src/consts/user-mobile/blank.const';
import { apiHome } from 'src/apis/utils/overview';
import { useRecoilState } from 'recoil';
import { apiFestival } from 'src/apis/utils/basic';
import {
  BoardSetWidth,
  Flex,
  MainBlock,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import CustomSliderMainBanner from 'src/components/user-mobile/home/CustomSliderMainBanner';
import forward from 'src/assets/images/user-mobile/Forward.svg';

import getDateISOString from 'src/utils/getDateISOString';
import { activeLanguageState } from 'src/recoils/user-mobile.atom';
import HomeMainMenu from 'src/components/user-mobile/home/HomeMainMenu';
import WidgetBoard from 'src/components/user-mobile/home/WidgetBoard';
import UpWidgetBoard from 'src/components/user-mobile/home/UpWidgetBoard';
import DownWidgetBoard from 'src/components/user-mobile/home/DownWidgetBoard';

import SplashImage from 'src/components/user-mobile/home/SplashImage';
import MissingPersonPopup from 'src/components/user-mobile/missing/MissingPersonPopup';
import { useQuery } from '@tanstack/react-query';
import LanguageDropdown from 'src/components/user-mobile/home/LanguageDropdown';
import getTranslateString from 'src/utils/getTranslateString';
import CustomSliderMiddleBanner from 'src/components/user-mobile/home/CustomSliderMiddleBanner';
import FooterUniv from 'src/components/user-mobile/home/FooterUniv';
import { SplashData } from 'src/interfaces/user-mobile/basic.interface';
import FooterFestival from 'src/components/user-mobile/home/FooterFestival';

function Home() {
  // 최초 렌더링시에만 값을 고정하도록 (setInterval의 영향을 받지 않도록)
  const [requestDate] = useState(getDateISOString(new Date()));
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [splashData, setSplashData] = useState<SplashData | null>(null); // 반응형을 위한 boradSet (위)//

  // 반응형을 위한 boradSet (위)//
  const refBoardSet = useRef<HTMLDivElement>(null);
  const [mainBlockWidth, setMainBlockWidth] = useState(0);
  useEffect(() => {
    setMainBlockWidth((refBoardSet.current?.offsetWidth || 32) - 32);
  }, [refBoardSet]);
  // 반응형을 위한 boradSet (아래)//

  // 추가 컴포넌트 바의 위치 조정을 위한 작업 (위)
  // 메서드로 추출하는 과정이 필요하다면 메서드 자체를 의존성배열에 추가
  // 추가 컴포넌트 바의 위치 조정을 위한 작업 (아래)
  const { festivalId, logoSrc } = useOutletContext<IFestivalOutlet>();
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentUpWidgetIndex, setCurrentUpWidgetIndex] = useState(0);

  const [upWidgetList, setUpWidgetList] = useState<IUpWidgetInfo[]>([
    { ...upWidgetInfoBlank },
  ]);
  const [bannerList, setBannerList] = useState<IBanner[]>([
    { ...bannerInfoBlank },
  ]);
  const [mainMenus, setMainMenus] = useState<IMainMenus>({ ...mainMenusBlank });
  const [middleBannerList, setMiddleBannerList] = useState<IMiddleBanner[]>([
    { ...middleBannerBlank },
  ]);
  const [widgetList, setWidgetList] = useState<IWidgetInfo[]>([
    { ...widgetInfoBlank },
  ]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [downWidgetList, setDownWidgetList] = useState<IDownWidgetInfo[]>([
    { ...downWidgetBlank },
    { ...downWidgetBlank },
    { ...downWidgetBlank },
  ]);

  const [lang, setLang] = useRecoilState(activeLanguageState);

  const [noticeEvent, setNoticeEvent] = useState('공지사항 / 이벤트');

  const { data: homeData } = useQuery({
    queryKey: ['home', festivalId, requestDate],
    queryFn: () =>
      apiHome
        .getHome(festivalId, requestDate)
        .then(response => response.data)
        .catch(error => console.log(error)),
  });

  useEffect(() => {
    const hasVisited = sessionStorage.getItem('hasVisited') === 'true'; // 값을 boolean으로 해석
    if (!hasVisited) {
      setLang('KOR');
      localStorage.setItem('language', 'ko');
      setIsSplashVisible(true); // 첫 방문 시에만 스플래시 화면 표시
      sessionStorage.setItem('hasVisited', 'true');
    } else {
      setIsSplashVisible(false); // 이미 방문한 경우 바로 숨김
    }

    const timer = setTimeout(() => {
      setIsSplashVisible(false); // 2.5초 후 Splash 화면 숨기기
    }, 2500);

    return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
  }, []);

  useEffect(() => {
    if (isSplashVisible && festivalId) {
      apiFestival
        .getSplash(festivalId)
        .then(response => setSplashData(response.data))
        .catch(error => console.log('데이터 가져오기 실패:', error));
    }
  }, [isSplashVisible, festivalId]);

  useEffect(() => {
    if (homeData) {
      setUpWidgetList(homeData.upWidgets);
      setWidgetList(homeData.widgetDto);
      setMainMenus(homeData.mainMenuDto);
      setMiddleBannerList(
        homeData.middleBannerDtos?.sort(
          (a: IMiddleBanner, b: IMiddleBanner) => a.bannerRank - b.bannerRank,
        ),
      );
      setBannerList(
        homeData.banner?.sort((a: IBanner, b: IBanner) => a.rank - b.rank),
      );

      // 이름 없는 고정컴포넌트 제거
      const filterdDownWidgetList = [] as IDownWidgetInfo[];
      homeData.downWidgetDtos?.forEach((item: IDownWidgetInfo) => {
        if (item.name !== (null && '')) filterdDownWidgetList.push(item);
      });
      setDownWidgetList([...filterdDownWidgetList]);
    }
  }, [homeData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUpWidgetIndex(prev => (prev + 1) % (upWidgetList?.length || 1));
    }, 2000);

    // 클린업 함수: 컴포넌트가 언마운트될 때 타이머 정리
    return () => clearInterval(interval);
  }, [upWidgetList?.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (lang !== 'KOR')
      getTranslateString('공지사항 / 이벤트')
        .then((res: string) => setNoticeEvent(res))
        .catch(er => {
          console.log(er, '공지사항 이벤트 번역 에러');
        });
    else setNoticeEvent('공지사항 / 이벤트');
  }, [lang]);

  return (
    <Wrapper>
      {/* 스플래시 이미지를 모달로 띄우기 */}
      {isSplashVisible && splashData?.background && (
        <SplashImage isVisible={isSplashVisible} festivalId={festivalId} />
      )}
      {/* {selectPopup && <SelectPopup />} */}
      <BoardSetWidth ref={refBoardSet} style={{ paddingBottom: '280px' }}>
        {/* Footer가 겹치는 현상을 막기 위한 패딩 추가 */}
        <TopFixedBarHome
          backgroundColor={theme.colors.backgroundColor}
          mainBlockWidth={mainBlockWidth}
        >
          <Logo src={logoSrc} />
          <LanguageDropdown />
        </TopFixedBarHome>

        <MainBlock mainBlockWidth={mainBlockWidth}>
          {upWidgetList?.length !== 0 && (
            <UpWidgetBoard
              upWidgetList={upWidgetList}
              currentUpWidgetIndex={currentUpWidgetIndex}
              subColor={theme.colors.subColor}
            />
          )}

          <CustomSliderMainBanner imageBoardInfo={bannerList} />

          <HomeMainMenu
            mainMenus={mainMenus}
            fontColor={theme.colors.fontColor}
            subColor={theme.colors.subColor}
          />
          <CustomSliderMiddleBanner imageBoardInfo={middleBannerList} />

          {/* 대학축제는 공지사항/이벤트 버튼 없음 */}
          {false && (
            <NoticeBtn onClick={() => navigate('/post/notice')}>
              {noticeEvent}
              <img src={forward} alt="" />
            </NoticeBtn>
          )}

          <WidgetBoardBlock>
            {widgetList?.map((item: IWidgetInfo) => {
              return <WidgetBoard item={item} />;
            })}
          </WidgetBoardBlock>

          {downWidgetList?.length !== 0 && (
            <DownWidgetBlock length={downWidgetList?.length}>
              {downWidgetList?.map((item: IDownWidgetInfo) => {
                return (
                  <DownWidgetBoard
                    item={item}
                    downWidgetList={downWidgetList}
                  />
                );
              })}
            </DownWidgetBlock>
          )}
        </MainBlock>
        <MissingPersonPopup />

        {/* //대학축제인지 지역축제인지 구분 */}
        {window.location.hostname.includes('wabauniv') ? (
          <FooterUniv festivalId={festivalId} />
        ) : (
          <FooterFestival />
        )}
      </BoardSetWidth>
    </Wrapper>
  );
}

export default Home;

const Logo = styled.img`
  width: auto;
  height: 32px;
  object-fit: contain;
`;

const NoticeBtn = styled.button`
  position: relative;
  width: 100%;
  height: 52px;
  flex-shrink: 0;

  border-radius: 16px;
  background: #fff;

  color: var(--Font-02_black, #111);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: 16px;

  > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
  }
`;

const DownWidgetBlock = styled(Flex)<{ length: number }>`
  width: 100%;
  height: 48px;

  background-color: white;
  border-radius: 16px;

  justify-content: space-between;
  align-items: center;
`;

const WidgetBoardBlock = styled.div`
  width: 100%;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;
