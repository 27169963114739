import {
  CONCERT_INFO,
  CONCERT_INFO_BACKGROUND,
} from 'src/assets/database/webData';
import { FlexBox, FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export default function WebConcertInfo() {
  return (
    <ContainerWebConcertInfo>
      <ConcertBackgroundImageBoard>
        <ConcertImageBoard src={CONCERT_INFO} />
      </ConcertBackgroundImageBoard>
    </ContainerWebConcertInfo>
  );
}
const ContainerWebConcertInfo = styled(FlexBox)`
  width: 100%;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const ConcertBackgroundImageBoard = styled(FlexBoxFullWidth)`
  width: 100%;
  background-image: url(${CONCERT_INFO_BACKGROUND});
  background-size: cover;
  background-position: top;

  //원본 가로사이즈보다 이미지가 커질 시 맨 왼쪽에 약간의 흰색 여백이 생기는 오류 방지(원인 불명)
  padding-top: 70px;

  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1600px) {
    width: 101%;
  }
`;

const ConcertImageBoard = styled.img`
  width: 1000px;

  margin: 0 auto;
`;
