import { FlexBox, GridBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import blank from 'src/assets/images/user-web/BlankImage.png';
import { IPost } from 'src/interfaces/user-mobile/post.interface';

interface IProps {
  noticeList: IPost[];
  onClickNoticeBox: React.MouseEventHandler<HTMLDivElement>;
}

export default function WebNoticeList({
  noticeList,
  onClickNoticeBox,
}: IProps) {
  return (
    <NoticeGridBox>
      {noticeList?.map(item => (
        <NoticeBox
          id={item.id.toString()}
          key={item.id}
          onClick={onClickNoticeBox}
        >
          <img src={item.thumbnail || blank} alt="공지사항 이미지" />
          <h1>{item.title}</h1>
          <h2>{item.time?.replace('T', ' ').slice(0, 19)}</h2>
        </NoticeBox>
      ))}
    </NoticeGridBox>
  );
}

const NoticeGridBox = styled(GridBox)`
  margin-top: 60px;
  margin-bottom: 120px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(
    100px,
    auto
  ); /* 행의 최소 높이를 100px로 설정하고 내용에 따라 자동으로 조정 */
  column-gap: 40px;
  row-gap: 32px;
`;

const NoticeBox = styled(FlexBox)`
  width: 280px;
  cursor: pointer;
  flex-direction: column;
  > img {
    width: 280px;
    height: 280px;
    object-fit: cover;
    object-position: top;
    margin-bottom: 12px;
    border-radius: 4px;
  }
  > h1 {
    color: #111;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.45px;
  }
  > h2 {
    color: #999;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
  }
`;
