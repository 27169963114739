import styled from 'styled-components';
import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import { FlexBox } from '../OverviewStyle';

export const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const BoardSetWidth = styled(Flex)`
  min-height: 100vh;
  position: relative;

  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    width: 390px;
  }
`;
export const BoardSetWidthWhite = styled(BoardSetWidth)`
  background-color: #fff;
`;

export const MainBlock = styled.div<{ mainBlockWidth: number }>`
  width: ${props => props.mainBlockWidth}px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 12px;
`;

export const BoardSetWidthInfoImageBoard = styled(BoardSetWidth)`
  /* background-color: #fff; */
  min-height: 100vh;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    min-height: -webkit-fill-available;
  }
`;

export const InfoImageBoard = styled(Flex)`
  width: 100%;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const RoundBox = styled.img`
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const ImgUploadSubTitle = styled(FlexBox)`
  gap: 8px;
  margin-bottom: 12px;
  align-items: flex-start;

  > h2 {
    margin-top: 0;
    color: var(--Font-02_black, #111);

    /* PC/Body/KR/B2_KR_Sb */
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.375px;
  }

  > h3 {
    color: var(--Font-02_black, #111);

    /* PC/Caption/KR/C2_KR_Rg */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 150% */
    letter-spacing: -0.3px;
  }
`;
