import imageBlank from 'src/assets/images/user-mobile/GrayBlank.png';

import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';
import { useEffect, useState } from 'react';
import { apiFestivalInfoCategory } from 'src/apis/utils/festivalInfo';
import {
  festivalInfoCategoryInfoBlank,
  festivalInfoCategoryBlank,
} from 'src/consts/user-mobile/blank.const';
import { useOutletContext } from 'react-router-dom';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';
import { IImageInfo } from 'src/interfaces/user-mobile/overview.interface';
import {
  BoardSetWidthInfoImageBoard,
  InfoImageBoard,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import TopFixedBarFestivalInfoMain from 'src/components/user-mobile/festival-info/TopFixedBarFestivalInfoMain';
import { useQuery } from '@tanstack/react-query';

export default function FestivalInfoMain({
  mainInfoNum,
}: {
  mainInfoNum: number;
}) {
  const { festivalId } = useOutletContext<IFestivalOutlet>();
  const type = mainInfoNum === 1 ? 'entry' : 'view';
  const MAIN_INFO_1_NAME = '무대 행사 프로그램';
  const MAIN_INFO_2_NAME = '공연 세부 일정';
  // mainInfoNum에 따른 구 type지정, 이름 지정

  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: festivalInfoCategoryList } = useQuery({
    queryKey: ['festivalInfoCategoryList', festivalId, type],
    queryFn: () =>
      apiFestivalInfoCategory
        .getFestivalInfoCategoryList(festivalId, type)
        .then(response => response.data.concertInfoGetDto)
        .catch(error => console.log(error)),
    placeholderData: [{ ...festivalInfoCategoryBlank }],
  });

  const { data: categoryInfo } = useQuery({
    queryKey: ['categoryInfo', festivalInfoCategoryList, currentIndex],
    queryFn: () =>
      apiFestivalInfoCategory
        .getFestivalInfoCategoryInfo(
          festivalInfoCategoryList[currentIndex]?.concertInfoId,
        )
        .then(response => response.data)
        .catch(error => console.log(error)),
    placeholderData: { ...festivalInfoCategoryInfoBlank, type },
  });

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Wrapper>
      <BoardSetWidthInfoImageBoard>
        <TopFixedBarFestivalInfoMain
          categoryName={mainInfoNum === 1 ? MAIN_INFO_1_NAME : MAIN_INFO_2_NAME}
          festivalInfoCategoryList={festivalInfoCategoryList}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
        <TopFixedBarBlank />
        <TopFixedBarBlank style={{ height: '46px' }} />

        <InfoImageBoard>
          {categoryInfo?.images.map((item: IImageInfo) => (
            <img
              key={item.image}
              id={item.imageId.toString()}
              src={item.image || imageBlank}
              alt="카테고리 이미지"
            />
          ))}
        </InfoImageBoard>
      </BoardSetWidthInfoImageBoard>
    </Wrapper>
  );
}
