import { WEB_THEME_COLOR } from 'src/consts/user-web.const';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100dvw;
  min-height: 100dvh;

  background-color: #fff;
`;

export const LogoContainer = styled.div`
  width: 100%;
  height: 166px;

  background: ${WEB_THEME_COLOR};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img<{ width: number }>`
  width: ${props => props.width}px;
  height: auto;
  object-fit: fill;
`;

export const TopMovingFixedBar = styled.div<{ top: boolean }>`
  position: ${props => (props.top ? 'fixed' : '')};
  top: ${props => (props.top ? '0' : '')};
  width: 100%;
  height: 56px;
  z-index: 10;
  flex-shrink: 0;
  background: ${WEB_THEME_COLOR};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopMovingFixedBarBlank = styled.div<{ top: boolean }>`
  display: ${props => (props.top ? 'block' : 'none')};
  width: 100%;
  padding: 18px 0;
`;

export const BtnBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
`;
export const BarBtn = styled.button<{ current: boolean }>`
  color: #fff;

  font-size: 16px;
  font-style: normal;
  font-weight: ${props => (props.current ? '700' : '300')};
  line-height: 140%; /* 144.444% */
  letter-spacing: -0.45px;

  &:hover {
    font-weight: 700;
  }
`;
export const Footer = styled(FlexBox)`
  width: 100%;
  height: 210px;
  flex-shrink: 0;

  background-color: ${WEB_THEME_COLOR};

  flex-direction: column;
  justify-content: center;
  align-items: center;

  > h1 {
    margin-bottom: 8px;

    color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }

  > h2 {
    margin-bottom: 20px;

    color: white;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 142.857% */
    letter-spacing: -0.35px;
  }

  > h3 {
    color: white;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 142.857% */
    letter-spacing: -0.35px;
    span {
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.35px;
    }
  }
`;

export const IconBox = styled(FlexBox)`
  margin-bottom: 16px;
  cursor: pointer;

  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const ScrollTopButton = styled.img`
  position: fixed;
  z-index: 100;
  bottom: 72px;
  right: 74px;
  cursor: pointer;
  opacity: 0.7;
`;
