import { createBrowserRouter } from 'react-router-dom';

import App from './App';

import Home from './pages/user-mobile/Home';
import PostList from './pages/user-mobile/post/PostList';
import PostDetail from './pages/user-mobile/post/PostDetail';
import LineUp from './pages/user-mobile/festival-info/LineUp';
import FestivalInfoMain from './pages/user-mobile/festival-info/FestivalInfoMain';
import FestivalInfoOthers from './pages/user-mobile/festival-info/FestivalInfotOthers';
import MapHome from './pages/user-mobile/map/MapHome';
import InquiryList from './pages/user-mobile/inquiry/InquiryList';
import InquiryDetail from './pages/user-mobile/inquiry/InquiryDetail';
import InquiryForm from './pages/user-mobile/inquiry/InquiryForm';
import StampStart from './pages/user-mobile/stamp/StampStart';
import StampHome from './pages/user-mobile/stamp/StampHome';
import StampQr from './pages/user-mobile/stamp/StampQr';
import StampSignUp from './pages/user-mobile/stamp/StampSignUp';
import StampLogin from './pages/user-mobile/stamp/StampLogin';
import StampDetail from './pages/user-mobile/stamp/StampDetail';
import StampComplete from './pages/user-mobile/stamp/StampComplete';
import StampFinished from './pages/user-mobile/stamp/StampFinished';

import LostItemHome from './pages/user-mobile/lost-item/LostItemHome';
import LostItemDetail from './pages/user-mobile/lost-item/LostItemDetail';
import MissingHome from './pages/user-mobile/missing/MissingHome';
import StampGuide from './pages/user-mobile/stamp/StampGuide';
import StampPrize from './pages/user-mobile/stamp/StampPrize';
import VotePreview from './pages/user-mobile/vote/VotePreview';
import ProgramOthersInfo from './pages/user-mobile/program/ProgramOthersInfo';
import ProgramHome from './pages/user-mobile/program/ProgramHome';
import FestivalInfoHome from './pages/user-mobile/festival-info/FestivalInfoHome';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />, // App.js의 역할
    children: [
      // 모바일 사용자 페이지
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'post/:type',
        element: <PostList />,
      },
      {
        path: 'post/notice/:id',
        element: <PostDetail />,
      },
      {
        path: 'post/event/:id',
        element: <PostDetail />,
      },

      {
        path: 'festival-info',
        element: <FestivalInfoHome />,
      },

      {
        path: 'festival-info/line-up',
        element: <LineUp />,
      },

      {
        path: 'festival-info/main/1',
        element: <FestivalInfoMain mainInfoNum={1} />,
      },

      {
        path: 'festival-info/main/2',
        element: <FestivalInfoMain mainInfoNum={2} />,
      },
      {
        path: 'festival-info/others/:id',
        element: <FestivalInfoOthers />,
      },
      {
        path: 'map',
        element: <MapHome />,
      },
      {
        path: 'inquiry',
        element: <InquiryList />,
      },
      {
        path: 'inquiry/:id',
        element: <InquiryDetail />,
      },
      {
        path: 'inquiry/form',
        element: <InquiryForm />,
      },
      {
        path: 'stamp/start',
        element: <StampStart />,
      },
      {
        path: 'stamp',
        element: <StampHome />,
      },
      { path: 'stamp/qr', element: <StampQr /> },
      { path: 'stamp/sign_up', element: <StampSignUp /> },
      { path: 'stamp/login', element: <StampLogin /> },
      { path: 'stamp/:id', element: <StampDetail /> },
      { path: 'stamp/complete', element: <StampComplete /> },
      { path: 'stamp/finished', element: <StampFinished /> },
      { path: 'stamp/guide', element: <StampGuide /> },
      { path: 'stamp/prize', element: <StampPrize /> },

      {
        path: '/lostItem',
        element: <LostItemHome />,
      },
      {
        path: '/lostItem/:id',
        element: <LostItemDetail />,
      },
      {
        path: '/missing',
        element: <MissingHome />,
      },
      {
        path: '/vote',
        element: <VotePreview />,
      },
      {
        path: '/program',
        element: <ProgramHome />,
      },
      {
        path: '/program/:id',
        element: <ProgramOthersInfo />,
      },
    ],
  },
  { path: '/access', element: <App /> },
]);

export default router;

// router함수가 App.js의 컴포넌트 역할 (Router.tsx는 App.js의 역할)
// 해당 router함수는 index에서 import됨
