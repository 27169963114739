// import home from 'src/assets/images/web/HomeImage.webp';
import {
  MAIN_BACKGROUND_1,
  MAIN_BACKGROUND_1_TEXT_IMAGE,
  MAIN_BACKGROUND_2,
  MAIN_CONTENT,
  MAIN_CONTENT_TIME_TABLE,
  MAIN_ENTIRE_SCHEDULE,
} from 'src/assets/database/webData';
import {
  BOARD_SET_WIDTH_WEB,
  FIRST_BOARD_TEXT_IMAGE_WIDTH,
  SECOND_BOARD_IMAGE_WIDTH,
} from 'src/consts/user-web.const';
import { FlexBox, FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export default function WebMainPage() {
  return (
    <BoardMainPage>
      <UrlDiv url={MAIN_BACKGROUND_1}>
        <UrlDivTextImage src={MAIN_BACKGROUND_1_TEXT_IMAGE} />
      </UrlDiv>

      {/* 이미지 확정될 때 까지 일단 숨김 */}
      {false && (
        <NotUrlDiv>
          <NotUrlDivImage src={MAIN_CONTENT} />
        </NotUrlDiv>
      )}

      <UrlDivContent url={MAIN_BACKGROUND_2}>
        <UrlDivImage src={MAIN_CONTENT_TIME_TABLE} />
      </UrlDivContent>
      <NotUrlDivEntireScedule>
        <NotUrlDivImage src={MAIN_ENTIRE_SCHEDULE} />
      </NotUrlDivEntireScedule>
    </BoardMainPage>
  );
}

const BoardMainPage = styled(FlexBox)`
  width: 100%;
  min-height: 100vh;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const UrlDiv = styled(FlexBoxFullWidth)<{ url: string }>`
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;

  padding: 120px 0 120px 0;

  justify-content: center;
  align-items: center;
`;

const UrlDivContent = styled(UrlDiv)<{ url: string }>`
  padding: 80px 0 270px 0;
`;
const UrlDivImage = styled.img`
  width: ${BOARD_SET_WIDTH_WEB}px;
`;

const UrlDivTextImage = styled.img`
  width: ${FIRST_BOARD_TEXT_IMAGE_WIDTH}px;
`;

const NotUrlDiv = styled(FlexBoxFullWidth)`
  background: linear-gradient(180deg, #fcfcfd 0%, #f1f1f7 100%);

  padding: 100px 0 100px 0;

  justify-content: center;
  align-items: center;
`;

const NotUrlDivImage = styled.img`
  width: ${SECOND_BOARD_IMAGE_WIDTH}px;
`;

const NotUrlDivEntireScedule = styled(NotUrlDiv)`
  padding: 80px 0 240px 0;
`;
