import { instance } from 'src/apis/index';
import { IInquiryInfoRequest } from 'src/interfaces/user-mobile/inquiry.interface';

export const apiInquiry = {
  // 문의사항 리스트 조회
  getInquiryListForUser: (festivalId: number) =>
    instance.get(`inquiry/forUser`, { params: { festivalId } }),

  // 문의사항 상세 조회 (비밀글 여부에 따른 password 입력)
  // 비밀글이 아닐 시 password는 빈 문자열로 전달
  postInquiryInfoGetForUser: (
    inquiryId: number,
    userId: string,
    password: string,
  ) =>
    instance.post(`inquiry/forUser/${inquiryId}`, {
      userId,
      password,
    }),

  // 문의사항 등록
  postInquiryInfo: (
    festivalId: number,
    inquiryInfoRequest: IInquiryInfoRequest,
  ) => instance.post(`inquiry`, inquiryInfoRequest, { params: { festivalId } }),
};
