// 관람안내, 입장방법, 기타 프로그램이 담겨있는 페이지 (들불축제 제작 중에 추가된 페이지)
import styled from 'styled-components';
import { useNavigate, useOutletContext } from 'react-router-dom';

import {
  TopFixedBar,
  TopFixedBarBlank,
} from 'src/styles/user-mobile/TopFixedBarStyle';

import diagonalDirection from 'src/assets/images/user-mobile/DiagonalDirection.svg';
import diagonalDirection2 from 'src/assets/images/user-mobile/DiagonalDirection2.svg';

import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useEffect, useRef, useState } from 'react';
import { IFestivalOutlet } from 'src/interfaces/overview.interface';

import grayBlankDiv from 'src/assets/images/user-mobile/GrayBlankDiv.svg';
import { festivalInfoMainBlank } from 'src/consts/user-mobile/blank.const';
import { apiFestivalInfoMain } from 'src/apis/utils/festivalInfo';
import {
  BoardSetWidthWhite,
  Flex,
  MainBlock,
  RoundBox,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import { useQuery } from '@tanstack/react-query';
import { experienceProgramData } from 'src/assets/database/programData';
import { PROGRAM_MAIN_BANNER } from 'src/assets/database/webData';
import { IExperienceProgramData } from 'src/interfaces/user-web.interface';
import { FlexBox } from 'src/styles/OverviewStyle';
import ProgramFooter from 'src/components/user-mobile/program/ProgramFooter';

export default function ProgramHome() {
  // 반응형을 위한 boradSet (위)//
  const refBoardSet = useRef<HTMLDivElement>(null);
  const [mainBlockWidth, setMainBlockWidth] = useState(0);
  useEffect(() => {
    setMainBlockWidth((refBoardSet.current?.offsetWidth || 32) - 32);
  }, [refBoardSet]);
  // 반응형을 위한 boradSet (아래)//

  const MAIN_PROGRAM_1_NAME = '무대 행사 프로그램';
  const MAIN_PROGRAM_2_NAME = '공연 세부 일정';
  // 메인 프로그램 이름 1,2 지정

  const navigate = useNavigate();
  const { festivalId } = useOutletContext<IFestivalOutlet>();

  const { data: entryInfo } = useQuery({
    queryKey: ['entryInfo', festivalId],
    queryFn: () =>
      apiFestivalInfoMain
        .getFestivalInfoMain(festivalId, 'entry')
        .then(response => response.data),
    placeholderData: { ...festivalInfoMainBlank },
  });

  const { data: viewInfo } = useQuery({
    queryKey: ['viewInfo', festivalId],
    queryFn: () =>
      apiFestivalInfoMain
        .getFestivalInfoMain(festivalId, 'view')
        .then(response => response.data),
    placeholderData: { ...festivalInfoMainBlank },
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Wrapper>
      <BoardSetWidthWhite ref={refBoardSet}>
        <TopFixedBar backgroundColor="#fff">
          <Flex style={{ marginLeft: '16px' }}>
            <BkBtn2 onClick={() => navigate(-1)} src={bkBtn2} />
            <H1>무대/체험 프로그램 정보</H1>
          </Flex>
          {/* <MenuBtn /> */}
        </TopFixedBar>
        <TopFixedBarBlank />
        <ProgramMainBoard url={PROGRAM_MAIN_BANNER}>
          <h1>무대 행사 프로그램</h1>

          <ProgramMainButtonBox>
            <button
              type="button"
              onClick={() => {
                navigate('/festival-info/main/1');
              }}
            >
              <h1>
                {MAIN_PROGRAM_1_NAME}
                <img alt="" src={diagonalDirection2} />
              </h1>
              <h2>{entryInfo.summary}</h2>
              {entryInfo.icon && (
                <RoundBox src={entryInfo.icon || grayBlankDiv} />
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                navigate('/festival-info/main/2');
              }}
            >
              <h1>
                {MAIN_PROGRAM_2_NAME}
                <img alt="" src={diagonalDirection2} />
              </h1>
              <h2>{viewInfo.summary}</h2>
              {viewInfo.icon && (
                <RoundBox src={viewInfo.icon || grayBlankDiv} />
              )}
            </button>
          </ProgramMainButtonBox>
        </ProgramMainBoard>

        <MainBlockPrograms mainBlockWidth={mainBlockWidth}>
          <h1>체험 프로그램</h1>
          {experienceProgramData?.map((item: IExperienceProgramData) => {
            const numNewLineChar =
              (item?.categoryName?.split('\n')?.length || 0) - 1;
            return (
              <ETCBtn
                onClick={() => {
                  navigate(`${item?.id}`);
                }}
                bgColor={item?.color2}
                h1FontSize={22 - 4 * numNewLineChar}
              >
                <TextBox>
                  {item?.categoryName?.split('\n').map((line, index) => {
                    if (index === item.categoryName.split('\n').length - 1)
                      return (
                        <h1>
                          {line}
                          <img src={diagonalDirection} alt="" />
                        </h1>
                      );
                    return <h1>{line}</h1>;
                  })}
                </TextBox>

                <img alt="" src={item?.image} />
              </ETCBtn>
            );
          })}
        </MainBlockPrograms>
        <ProgramFooter />
      </BoardSetWidthWhite>
    </Wrapper>
  );
}

const ProgramMainBoard = styled(FlexBox)<{ url: string }>`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 60px;
  padding: 24px 16px 60px 16px;

  background-image: url(${props => props.url});
  background-position: center;
  background-size: 400%;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  > h1 {
    color: #fff;
    font-family: Cafe24Ohsquare;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 39.2px */
  }
`;
const ProgramMainButtonBox = styled(FlexBox)`
  align-self: center;
  flex-shrink: 0;

  width: 100%;

  flex-direction: column;
  justify-content: center;
  gap: 12px;
  button {
    position: relative;
    width: 100%;
    height: 88px;

    border-radius: 12px;
    background: #fff;

    padding: 16px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    h1 {
      color: #101010;
      font-family: Cafe24Ssurround;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 28px */

      z-index: 5;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      img {
        width: 18px;
        height: 18px;
        margin-bottom: 3px;
      }
    }
    h2 {
      z-index: 5;

      color: var(--Font-03_Gray, #505050);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    ${RoundBox} {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);

      width: auto;
      height: 120px;

      opacity: 0.5;
      z-index: 0;
    }
  }
`;

const MainBlockPrograms = styled(MainBlock)`
  padding: 40px 0 100px 0;
  align-items: flex-start;
  > h1 {
    color: #101010;
    font-family: Cafe24Ohsquare;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 39.2px */

    margin-bottom: 4px;
  }
`;

const ETCBtn = styled.button<{ bgColor: string; h1FontSize: number }>`
  position: relative;
  width: 100%;
  height: 100px;
  flex-shrink: 0;
  border-radius: 12px;

  background-color: ${({ bgColor }) => bgColor};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);

  padding: 16px;

  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: #101010;
    font-family: Cafe24Ssurround;
    font-size: ${({ h1FontSize }) => h1FontSize}px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 30.8px */
    z-index: 7;

    white-space: pre-line;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    > img {
      width: 18px;
      height: 18px;
      margin-bottom: 3px;
    }
  }

  h2 {
    color: var(--Font-03_Gray, #505050);
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
  }

  > img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    height: 85px;
    z-index: 6;
  }
`;
const TextBox = styled(FlexBox)`
  position: absolute;
  top: 16px;
  left: 18px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const BkBtn2 = styled.img`
  margin-right: 14px;
  cursor: pointer;
`;

const H1 = styled.h1`
  color: #111;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
`;
