import { FlexBox } from 'src/styles/OverviewStyle';
import { useEffect, useState } from 'react';
import { IGetBoothSingle } from 'src/interfaces/user-mobile/map.interface';
import { apiBooth } from 'src/apis/utils/map';
import getTranslateString from 'src/utils/getTranslateString';

import defaultImg from 'src/assets/images/user-mobile/map/defaultImg.png';
import { IGetBoothSingleBlank } from 'src/consts/user-mobile/map/blank.const';
import MapFull from 'src/components/user-mobile/map/MapFull';
import * as S from './ClickPopupStyle';

function ClickInfo({ id }: { id: number }) {
  const [isFull, setIsFull] = useState(false);
  const [data, setData] = useState<IGetBoothSingle>({
    ...IGetBoothSingleBlank,
  });
  const [name, setName] = useState('');
  const [summary, setSummary] = useState('');
  const [operationHours, setOperationHours] = useState('');
  const [buttonName, setButtonName] = useState('');

  useEffect(() => {
    setSummary('');

    apiBooth
      .getBoothSingle(id)
      .then(res => {
        console.log('📌 Fetched Data:', res.data); // 데이터 확인용 로그
        setData(res.data);

        if (res.data.name) {
          getTranslateString(res.data.name).then(translatedName => {
            setName(translatedName);
          });
        }

        if (res.data.summary) {
          getTranslateString(res.data.summary).then(translatedSummary => {
            setSummary(translatedSummary);
          });
        }

        if (res.data.operationHours) {
          getTranslateString(res.data.operationHours).then(translatedHours => {
            setOperationHours(translatedHours);
          });
        }

        if (res.data.buttonName) {
          getTranslateString(res.data.buttonName).then(translateBtnName => {
            setButtonName(translateBtnName);
          });
        }
      })
      .catch(err => {
        console.error('❌ API Fetch Error:', err);
      });
  }, [id]);

  // ✅ `data.content` 또는 `data.menus`가 있을 때만 클릭 가능하도록 설정
  const hasContentOrMenus =
    data.content || (Array.isArray(data.menus) && data.menus.length > 0);

  const handleFull = () => {
    console.log('⚡ Checking Data in handleFull:', data); // 디버깅용 로그
    if (summary && hasContentOrMenus) {
      setIsFull(true);
    }
  };

  const handleLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (data?.url) {
      window.location.href = `${data.url}`;
    }
  };

  return (
    <>
      {isFull ? (
        <MapFull data={data} setIsFull={setIsFull} />
      ) : (
        <S.Map>
          {/* ✅ 클릭 자체를 막음 */}
          <S.Container
            onClick={summary && hasContentOrMenus ? handleFull : undefined}
          >
            <S.InBox>
              <S.TextBox>
                <S.Name>{name}</S.Name>
                {summary && (
                  <S.Summary dangerouslySetInnerHTML={{ __html: summary }} />
                )}
                <FlexBox style={{ gap: '4px' }}>
                  <S.Time>{operationHours}</S.Time>
                </FlexBox>
              </S.TextBox>
              {data?.thumbnail ? (
                <S.Img src={data?.thumbnail} />
              ) : (
                <S.Img src={defaultImg} />
              )}
            </S.InBox>
            {data?.buttonName && (
              <S.LinkBox onClick={handleLink}>
                {data?.buttonImage && <S.LinkImg src={data?.buttonImage} />}
                <S.LinkName>{buttonName}</S.LinkName>
              </S.LinkBox>
            )}
          </S.Container>
        </S.Map>
      )}
    </>
  );
}

export default ClickInfo;
