import {
  imgStyle,
  markerStyle,
  textStyle,
} from 'src/consts/user-mobile/map/style.const';
import markerImg from 'src/assets/images/user-mobile/map/markers/markerImg.svg';

export const getMarkerSize = (e: { name: string }) => {
  const markerDiv = document.createElement('div');
  markerDiv.style.position = 'absolute';
  markerDiv.innerHTML = `
      <div style="${markerStyle}">
        <img style="${imgStyle}" src="${markerImg}" />
        <div style="${textStyle}">${e?.name}</div>
      </div>
    `;

  document.body.appendChild(markerDiv);
  const width = markerDiv.offsetWidth;
  const height = markerDiv.offsetHeight;
  document.body.removeChild(markerDiv);

  return [width, height];
};
