import { FOOTER_FESTIVAL_NAME } from 'src/assets/database/footerData';
import { FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

export default function ProgramFooter() {
  return <ProgramFooterBlock>{FOOTER_FESTIVAL_NAME}</ProgramFooterBlock>;
}

const ProgramFooterBlock = styled(FlexBoxFullWidth)`
  height: 60px;

  background: #323248;
  padding-top: 12px;

  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.325px;

  justify-content: center;
  align-items: flex-start;
`;
