import { TopFixedBarBlank } from 'src/styles/user-mobile/TopFixedBarStyle';

import { useEffect } from 'react';
import { apiFestivalInfoCategory } from 'src/apis/utils/festivalInfo';
import { festivalInfoCategoryInfoBlank } from 'src/consts/user-mobile/blank.const';
import { useParams } from 'react-router-dom';
import TopFixedBarFestivalInfoOthers from 'src/components/user-mobile/festival-info/TopFixedBarFestivalInfoOthers';
import {
  BoardSetWidthInfoImageBoard,
  InfoImageBoard,
  Wrapper,
} from 'src/styles/user-mobile/OverviewStyle';
import imageBlank from 'src/assets/images/user-mobile/GrayBlank.png';
import { IImageInfo } from 'src/interfaces/user-mobile/overview.interface';
import { useQuery } from '@tanstack/react-query';

export default function FestivalInfoOthers() {
  const concertInfoId = Number(useParams().id);

  const { data: categoryInfo } = useQuery({
    queryKey: ['categoryInfo', concertInfoId],
    queryFn: () =>
      apiFestivalInfoCategory
        .getFestivalInfoCategoryInfo(concertInfoId)
        .then(response => response.data)
        .catch(error => console.log(error)),
    placeholderData: { ...festivalInfoCategoryInfoBlank, type: 'others' },
  });

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Wrapper>
      <TopFixedBarFestivalInfoOthers categoryName={categoryInfo?.name} />
      <BoardSetWidthInfoImageBoard>
        <TopFixedBarBlank />
        <InfoImageBoard>
          {categoryInfo?.images.map((item: IImageInfo) => (
            <img
              key={item.image}
              id={item.imageId.toString()}
              src={item.image || imageBlank}
              alt="카테고리 이미지"
            />
          ))}
        </InfoImageBoard>
      </BoardSetWidthInfoImageBoard>
    </Wrapper>
  );
}
