import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { apiFestival } from 'src/apis/utils/basic';

export interface SplashImageProps {
  isVisible: true;
  festivalId: number;
}
function SplashImage({ isVisible, festivalId }: SplashImageProps) {
  const [splashImages, setSplashImages] = useState({
    background: '',
    top: '',
    center: '',
    bottom: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSplashImages = async () => {
      try {
        const response = await apiFestival.getSplash(festivalId);
        setSplashImages(response.data);
      } catch (error) {
        console.log('스플래시 이미지 가져오기 실패:', error);
      }
    };

    if (isVisible && festivalId !== 0) {
      fetchSplashImages();
    }
  }, [isVisible, festivalId]);

  const handleBackgroundImageLoad = () => {
    setIsLoading(false);
  };

  if (!isVisible) return null;

  if (isLoading && splashImages.background) {
    return (
      <LoadingOverlay>
        <Spinner />
        <img
          src={splashImages.background}
          alt=" "
          onLoad={handleBackgroundImageLoad}
          style={{ display: 'none' }}
        />
      </LoadingOverlay>
    );
  }

  return (
    <ModalOverlayBlank>
      {splashImages.background && (
        <ModalOverlayWrapper>
          <ModalOverlay src={splashImages.background} alt=" " />
        </ModalOverlayWrapper>
      )}
      <ModalContent>
        <SplashContainer>
          {splashImages.top && (
            <TopSection src={splashImages.top} alt="상단 이미지" />
          )}
          {splashImages.center && (
            <CenterSection src={splashImages.center} alt="중앙 로고" />
          )}
          {splashImages.bottom && (
            <BottomSection src={splashImages.bottom} alt="하단 이미지" />
          )}
        </SplashContainer>
      </ModalContent>
    </ModalOverlayBlank>
  );
}

export default SplashImage;

const backgroundZoom = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ModalOverlayBlank = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
`;

const ModalOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${backgroundZoom} 2s ease-out 0.5s forwards;

  @media (min-width: 1078px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ModalOverlay = styled.img`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  object-position: center;

  @media (min-width: 1078px) {
    width: auto;
    height: 100%;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  max-width: 400px;
`;

const SplashContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const TopSection = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 375px;
  height: auto;
  z-index: 1;
`;

const CenterSection = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 375px;
  height: auto;
  z-index: 2;
`;

const BottomSection = styled.img`
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  object-fit: contain;
  z-index: 1;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 200;
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 165, 0, 0.3);
  border-top: 5px solid #ffa500;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;
