// import styled from "styled-components";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IImageInfo } from 'src/interfaces/user-mobile/overview.interface';

import grayBlank from 'src/assets/images/user-mobile/GrayBlank.png';
import { useEffect, useRef, useState } from 'react';
import {
  SliderBoard,
  SliderImgBoard,
  SliderIndex,
} from 'src/styles/user-mobile/CustomSliderStyle';
import styled from 'styled-components';
import { FlexBox } from 'src/styles/OverviewStyle';

interface IProps {
  imagesInfo: IImageInfo[];
  // 여기 '?'는 있든 말든 상관 없다는 뜻. 인터페이스를 같이 쓸 때 활용
  // 상위 컴포넌트에서 prop을 받을 때 설정필수
}

function CustomSliderPost({ imagesInfo }: IProps) {
  // currentIndex로 데이터를 바로 띄워줘야 함 => 0이 초기값
  const [currentIndex, setCurrentIndex] = useState(0);
  const indexList = imagesInfo?.map((_, index) => index);
  const refImgBoard = useRef<HTMLImageElement>(null);
  const refSlider = useRef<Slider>(null);

  const settings = {
    dots: false, // 슬라이드 바닥에 점을 보이게 할 것인지 설정
    arrows: false,
    infinite: true, // 무한 반복되게 할 것인지 설정
    speed: 300, // 슬라이드하는데 걸리는 시간 설정
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 개수
    slidesToScroll: 1, // 슬라이드 넘어갈 때마다 몇 개의 슬라이드를 넘길 것인지 설정
    autoplay: true, // 자동으로 슬라이드를 넘길 것인지 설정

    afterChange: (current: number) => {
      // event처럼 이미 current자리는 현재 슬라이드 번호에 대한 인자임
      setCurrentIndex(current);
    }, // 현재 슬라이드 위치에 따른 변화 get가능
  };

  const onClickIndex = (event: React.MouseEvent<HTMLDivElement>) => {
    const index = Number(event.currentTarget.dataset.index);
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (refSlider.current) {
      refSlider.current.slickGoTo(currentIndex);
      // 슬라이더 인덱스 내가 컨트롤
    }
  }, [currentIndex]);

  return (
    <SliderBoard>
      {imagesInfo.length > 1 ? (
        <StyledSlider {...settings} ref={refSlider}>
          {imagesInfo?.map(
            // imagesInfo가 interface에서 '?'표시가 되어있기 때문에, 안전하지 않음.
            // 때문에 imagesInfo에도 '?'추가
            (item: IImageInfo) => (
              <SliderImgBoard ref={refImgBoard} src={item.image} />
            ),
          )}
        </StyledSlider>
      ) : (
        imagesInfo.length === 1 && (
          <SliderImgBoard src={imagesInfo[0]?.image || grayBlank} />
        )
      )}

      {imagesInfo.length !== 0 && (
        <SliderIndexPost>
          {indexList.map((item: number) => (
            <SliderIndexDotPost
              key={item}
              onClick={onClickIndex}
              data-index={item}
              current={item === currentIndex}
              currentLeft={item === currentIndex - 1}
              currentRight={item === currentIndex + 1}
            />
          ))}
        </SliderIndexPost>
      )}
    </SliderBoard>
  );
}

export default CustomSliderPost;

const StyledSlider = styled(Slider)`
  background-color: transparent;
  overflow: hidden;
`;

const SliderIndexPost = styled(SliderIndex)`
  position: relative;
  width: 100%;
  height: 22px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
const SliderIndexDotPost = styled(FlexBox)<{
  current: boolean;
  currentLeft: boolean;
  currentRight: boolean;
}>`
  width: ${props => (props.current ? 24 : 6)}px;
  margin-left: ${props =>
    props.current ? -((24 - 10) / 2) : props.currentRight ? 4.5 : 0}px;
  margin-right: ${props =>
    props.current ? -((24 - 10) / 2) : props.currentLeft ? 4.5 : 0}px;

  height: 6px;
  border-radius: 3px;
  background-color: ${props =>
    props.current ? props.theme.colors.fontColor : '#E5E5EC'};

  transition: all 0.2s ease-in-out;
  cursor: pointer;
`;
