import { INIT_MAP_OPTION } from 'src/consts/user-mobile/map/overview.const';

export const getMapOption = (latlng: naver.maps.LatLng) => {
  const mapOption = INIT_MAP_OPTION;

  mapOption.center = latlng;

  return mapOption;
};

export default getMapOption;
