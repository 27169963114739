import MarkdownViewer from 'src/components/user-mobile/post/MarkDownViewer';
import { FlexBox } from 'src/styles/OverviewStyle';
import styled from 'styled-components';
import { INoticeInfo } from 'src/interfaces/user-web.interface';
import WebBackButton from '../WebBackButton';
import CustomSliderWebNotice from './CustomSliderWebNotice';

interface IProps {
  noticeInfo: INoticeInfo;
  onClickBackButton: React.MouseEventHandler<HTMLImageElement>;
}
export default function WebNoticeDetail({
  noticeInfo,
  onClickBackButton,
}: IProps) {
  return (
    <NoticeDetailBox>
      <WebBackButtonNotice onClickBackButton={onClickBackButton} />
      <NoticeDetailBoxTextBox>
        <h1>{noticeInfo?.title}</h1>
        <h2>{noticeInfo?.createTime.replace('T', ' ').slice(0, 19)}</h2>
      </NoticeDetailBoxTextBox>
      <SliderBoardWebNotice>
        <CustomSliderWebNotice imagesInfo={noticeInfo?.images || []} />
      </SliderBoardWebNotice>
      <MarkdownViewer content={noticeInfo.content} />
    </NoticeDetailBox>
  );
}

const NoticeDetailBox = styled(FlexBox)`
  width: 560px;
  margin-top: 40px;
  margin-bottom: 120px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const WebBackButtonNotice = styled(WebBackButton)`
  align-self: flex-start;
  margin-bottom: 16px;
  cursor: pointer;
`;
const NoticeDetailBoxTextBox = styled(FlexBox)`
  width: 100%;
  margin-bottom: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  > h1 {
    color: #111;
    font-family: 'Jalnan';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.6px;
  }
  > h2 {
    color: var(--Font-03_Gray, #505050);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
`;

const SliderBoardWebNotice = styled.div`
  width: 100%;
  height: 100%;
`;
