// export const textStyle = `
//   position: absolute
//   font-size: 1px;
//   line-height: normal;
//   letter-spacing: -0.3px;
//   font-weight: 600;
//   color: #111;
//   text-align: center;
//   text-shadow: -1px 0px #FFF, 0px 1px #FFF, 1px 0px #FFF, 0px -1px #FFF;
//   white-space: nowrap;
// `; <<오류있던 코드

export const textStyle = `
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.3px;
  font-weight: 600;
  color: #111;
  text-align: center;
   white-space: nowrap;
   text-shadow: -1px 0px #FFF, 0px 1px #FFF, 1px 0px #FFF, 0px -1px #FFF;
`;

export const imgStyle = `
  width: 28px;
  height: 28px;
  display: block; 
  margin: 0 auto; 
`;

export const fixedImgStyle = `
width: 48x;
height: 48px;
display: block; 
margin: 0 auto; 
`;
export const smallFixedImgStyle = `
width: 48px;
height: 48px;
display: block; 
margin: 0 auto; 
z-index: 3;
`;
export const markerStyle = `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
`;
export const noNamemarkerStyle = `
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
`;
export const noNametextStyle = `
visibility : hidden;
font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
  font-weight: 600;
  color: #111;
  text-align: center;
  -webkit-text-stroke-width: 0.1px;
  -webkit-text-stroke-color: #FFF;
  margin: 0;
  padding: 0;`;
