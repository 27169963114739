import instagram from 'src/assets/images/user-mobile/Instagram.svg';
import facebook from 'src/assets/images/user-mobile/Facebook.svg';
import youtube from 'src/assets/images/user-mobile/Youtube.svg';
import {
  ContainerFooter,
  FooterIconBox,
} from 'src/styles/user-mobile/FooterStyle';
import styled from 'styled-components';
import {
  FOOTER_ADDRESS,
  FOOTER_COPYRIGHT,
  FOOTER_FESTIVAL_NAME,
  FOOTER_PHONE_NUMBER,
} from 'src/assets/database/footerData';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  YOUTUBE_URL,
} from 'src/assets/database/overviewData';

export default function FooterFestival() {
  return (
    <StyledContainerFooter>
      <StyledFooterIconBox>
        <img
          onClick={() => window.open(FACEBOOK_URL)}
          src={facebook}
          alt="페이스북 이미지"
        />
        <img
          onClick={() => window.open(INSTAGRAM_URL)}
          src={instagram}
          alt="인스타그램 이미지"
        />
        <img
          onClick={() => window.open(YOUTUBE_URL)}
          src={youtube}
          alt="유튜브 이미지"
        />
      </StyledFooterIconBox>
      <h1>
        {FOOTER_FESTIVAL_NAME} <br />
      </h1>
      <h2>
        {FOOTER_ADDRESS}
        <br />
        {FOOTER_PHONE_NUMBER}
        <br />
        {FOOTER_COPYRIGHT}
      </h2>
      <h3>
        축제 디지털 가이드 제작 전문기업 <span>페스티맵</span>
      </h3>
    </StyledContainerFooter>
  );
}

const StyledContainerFooter = styled(ContainerFooter)`
  h1 {
    color: var(--Font-03_Gray, #505050);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;

    margin-bottom: 12px;
  }

  h2 {
    margin-bottom: 24px;

    color: var(--Font-04_Gray, #767676);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.3px;
  }

  h3 {
    color: var(--Font-04_Gray, #767676);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: -0.3px;
    span {
      color: var(--Font-03_Gray, #505050);

      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.35px;
    }
  }
`;

const StyledFooterIconBox = styled(FooterIconBox)`
  gap: 8px;
  img {
    opacity: 0.7;
  }
`;
