import {
  PROGRAM_CONTENT,
  PROGRAM_MAIN_BANNER,
  PROGRAM_MAIN_DAY1,
  PROGRAM_MAIN_DAY2,
  PROGRAM_MAIN_DAY3,
} from 'src/assets/database/webData';
import { BOARD_SET_WIDTH_WEB } from 'src/consts/user-web.const';
import { FlexBoxFullWidth } from 'src/styles/OverviewStyle';
import styled from 'styled-components';

interface IProps {
  setCurrentBtnId: React.Dispatch<React.SetStateAction<number>>;
  setCurrentMenu: React.Dispatch<React.SetStateAction<string>>;
}

export default function WebProgramMain({
  setCurrentBtnId,
  setCurrentMenu,
}: IProps) {
  return (
    <ContainerProgramMain>
      <BannerMain url={PROGRAM_MAIN_BANNER}>무대 행사 프로그램</BannerMain>
      <ProgramBoard>
        <ProgramImage src={PROGRAM_MAIN_DAY1} />
        <ProgramImage src={PROGRAM_MAIN_DAY2} />
        <ProgramImage src={PROGRAM_MAIN_DAY3} />
        <ProgramImage src={PROGRAM_CONTENT} />
        <ConcertInfoButton
          onClick={() => {
            setCurrentBtnId(4);
            setCurrentMenu('concertInfo');
          }}
        >
          공연 세부정보 확인
        </ConcertInfoButton>
      </ProgramBoard>
    </ContainerProgramMain>
  );
}

const ContainerProgramMain = styled(FlexBoxFullWidth)`
  padding-bottom: 280px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const BannerMain = styled(FlexBoxFullWidth)<{ url: string }>`
  height: 240px;
  background-position: center;
  background-size: cover;
  background-image: url(${({ url }) => url});

  color: #fff;
  text-align: center;
  font-family: Cafe24Ohsquare;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 56px */

  justify-content: center;
  align-items: center;
`;

const ProgramBoard = styled(FlexBoxFullWidth)`
  position: relative;
  padding-top: 80px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
`;

const ProgramImage = styled.img`
  width: ${BOARD_SET_WIDTH_WEB}px;
  &:last-of-type {
    margin-top: 96px;
  }
`;

const ConcertInfoButton = styled.button`
  position: absolute;
  top: 824px;
  left: calc(50% + 504px);
  transform: translateX(-50%);
  padding: 6px 12px;
  border-radius: 32px;
  background: #f84851;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.48);

  color: #fff;
  text-align: right;
  font-family: 'Pretendard Variable';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */
  letter-spacing: -0.225px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;
