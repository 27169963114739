import { IMG_BUCKET_PC } from 'src/consts/user-web.const';

export const MAIN_BACKGROUND_1 = `${IMG_BUCKET_PC}MainBackground1.webp`;
export const MAIN_BACKGROUND_1_TEXT_IMAGE = `${IMG_BUCKET_PC}MainPageLogo.webp`;
export const MAIN_CONTENT = `${IMG_BUCKET_PC}MainContent.webp`;
export const MAIN_BACKGROUND_2 = `${IMG_BUCKET_PC}MainBackground2.webp`;
export const MAIN_CONTENT_TIME_TABLE = `${IMG_BUCKET_PC}MainContentTimeTable.webp`;
export const MAIN_ENTIRE_SCHEDULE = `${IMG_BUCKET_PC}MainEntireSchedule.webp`;

export const SCROLL_TOP_BUTTON = `${IMG_BUCKET_PC}ScrollTop.webp`;

export const PROGRAM_MAIN_BANNER = `${IMG_BUCKET_PC}MainProgramBanner.webp`;
export const PROGRAM_MAIN_DAY1 = `${IMG_BUCKET_PC}MainProgramDay1.webp`;
export const PROGRAM_MAIN_DAY2 = `${IMG_BUCKET_PC}MainProgramDay2.webp`;
export const PROGRAM_MAIN_DAY3 = `${IMG_BUCKET_PC}MainProgramDay3.webp`;
export const PROGRAM_CONTENT = `${IMG_BUCKET_PC}MainProgramContent.webp`;

export const CONCERT_INFO_BACKGROUND = `${IMG_BUCKET_PC}ConcertInfoBackground.webp`;
export const CONCERT_INFO = `${IMG_BUCKET_PC}ConcertInfo.webp`;
