import styled from 'styled-components';
import { PAD_MAX_WIDTH } from 'src/consts/user-mobile/overview.const';
import { BoardSetWidth } from './OverviewStyle';
import { FlexBox } from '../OverviewStyle';

export const BoardSetWidthProgram = styled(BoardSetWidth)`
  /* background-color: #fff; */
  min-height: 100vh;
  @media screen and (min-width: ${PAD_MAX_WIDTH}px) {
    min-height: -webkit-fill-available;
  }
`;

export const ProgramBanner = styled(FlexBox)<{ color: string }>`
  position: relative;
  width: 100%;
  height: 80px;

  padding-left: 20px;

  background-color: ${({ color }) => color};

  justify-content: flex-start;
  align-items: center;
  > h1 {
    color: #fff;
    font-family: Cafe24Ssurround;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 36.4px */
    z-index: 5;
  }
  > img {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 100px;
    opacity: 0.5;
  }
`;

export const ProgramListBoard = styled(FlexBox)`
  width: 100%;

  z-index: 5;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;
export const ProgramElement = styled(FlexBox)`
  padding: 32px 20px;

  background-color: #fff;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &:last-of-type {
    padding-bottom: 80px;
  }
  //footer와의 하단 마진 유지
`;
export const Date = styled.p`
  margin-bottom: 8px;

  color: #101010;

  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */
`;
export const Title = styled.p`
  margin-bottom: 18px;

  color: #101010;
  font-family: Cafe24Ssurround;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
`;
export const Explanation = styled.p`
  margin-bottom: 28px;

  color: #101010;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
`;
export const ProgramExtraInfoBox = styled(FlexBox)`
  margin-bottom: 60px;

  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
`;
export const ProgramExtraInfo = styled(FlexBox)`
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  h1,
  h2 {
    color: #505050;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    white-space: pre-line;
  }
  h2 {
    font-weight: 600;
  }
`;
export const VerticalLine = styled.div`
  width: 1px;
  height: 12px;
  background-color: #505050;
`;

export const ProgramButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  padding: 14px 12px;

  border-radius: 8px;
  background: ${({ disabled }) => (disabled ? '#F1F1F6' : '#f84851')};

  color: ${({ disabled }) => (disabled ? '#909090' : '#fff')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 21px */
  letter-spacing: -0.225px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
