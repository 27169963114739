import { instance } from 'src/apis/index';

export const apiFestivalInfoMain = {
  getFestivalInfoMain: (festivalId: number, type: string) =>
    instance.get(`/festival/${festivalId}/${type}`),
};

export const apiFestivalInfoCategory = {
  getFestivalInfoCategoryList: (festivalId: number, type: string) =>
    instance.get(`/concertInfo/${festivalId}`, { params: { type } }),

  getFestivalInfoCategoryInfo: (concertId: number) =>
    instance.get('/concertInfo', { params: { concertId } }),
};

export const apiLineUp = {
  getLineUps: (festivalId: number) =>
    instance.get('/concert', { params: { festivalId } }),
  getLineUpInfo: (concertId: number) => instance.get(`/concert/${concertId}`),
};
