/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import nowLocation from 'src/assets/images/user-mobile/map/nowLocation.svg';
import nowLocationImg from 'src/assets/images/user-mobile/map/nowLocationImg.svg';

interface LocateButtonProps {
  mapRef: any;
  naver: any;
}

function LocateButton({ mapRef, naver }: LocateButtonProps) {
  const [myLocationMarker, setMyLocationMarker] =
    useState<naver.maps.Marker | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleMyLocation = () => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          const location = new naver.maps.LatLng(latitude, longitude);

          if (myLocationMarker) {
            myLocationMarker.setPosition(location);
          } else {
            const newMarker = new naver.maps.Marker({
              position: location,
              map: mapRef.current ? mapRef.current : undefined,
              icon: {
                content: `<img style="width: 28px; height: 28px;" src=${nowLocation}></img>`,
              },
            });
            setMyLocationMarker(newMarker);
          }

          mapRef.current?.morph(location);

          setIsLoading(false);
        },
        error => {
          console.error(error);
          setIsLoading(false);
          alert(
            '위치를 가져오는 데 실패했습니다. 다른 브라우저를 이용해주세요.',
          );
        },
        {
          enableHighAccuracy: true,
        },
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {},
        () => {},
      ); // 함수 인자로 화살표 함수 일단 전달
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }, []);

  return (
    <Button onClick={handleMyLocation}>
      {isLoading ? <Spinner /> : <img src={nowLocationImg} alt="위치이미지" />}
    </Button>
  );
}

export default LocateButton;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${rotate} 1s linear infinite;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;

  position: absolute;

  bottom: 16px;
  left: 16px;

  z-index: 10;

  background-color: #fff;
  color: #000;
  font-weight: 600;
  border: none;
  border-radius: 24px;
  border: 1px solid var(--Line-Regular_Color, #e5e5ec);
  background: var(--BG-White_Color, #fff);

  cursor: pointer;
  &:hover {
    background-color: #d9d9d9;
  }
`;
