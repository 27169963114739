import styled from 'styled-components';

import bkBtn2 from 'src/assets/images/user-mobile/BackBtn2.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Flex } from 'src/styles/user-mobile/OverviewStyle';
import { MovingBar } from 'src/styles/user-mobile/MovingBarStyle';

import {
  BkBtn,
  SelectBar,
  TopFixedBar,
  TopFixedBlock,
} from 'src/styles/user-mobile/TopFixedBarStyle';

export interface IFestival {
  categoryInfo: string;
  dayList: number[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function TopFixedBarLineUp({
  dayList,
  setCurrentIndex,
  categoryInfo,
  currentIndex,
}: IFestival) {
  const navigate = useNavigate();
  const refSelectBar = useRef<HTMLDivElement>(null);
  // <HTMLDivElement>이거 안 하면 refSelectBar?.current?.offsetWidth 할 때 오류남

  const dayListLength = dayList?.length;

  // 애니메이션 적용할 때 그냥 빈값을 주면 초기위치 잘 찾아감
  const [interval, setInterval] = useState(0);
  const [initial, setInitial] = useState(0);
  const [pos, setPos] = useState(`${initial}%`);

  const onClickDay = (event: React.MouseEvent<HTMLHeadingElement>) => {
    event.preventDefault();

    const index = Number(event.currentTarget.dataset.index);
    // div나 h에는 value 프로퍼티가 없으므로
    setPos(`${initial + index * interval}%`);
    setCurrentIndex(index);
  };

  useEffect(() => {
    setInterval(100 / dayListLength);
    setInitial(100 / (dayListLength * 2));
    setPos(`${100 / (dayListLength * 2)}%`);
  }, [dayListLength]);

  return (
    <TopFixedBlock>
      <StyledTopFixedBar>
        <Flex style={{ marginLeft: '16px' }}>
          <BkBtn src={bkBtn2} onClick={() => navigate(-1)} />
          <h1>{categoryInfo}</h1>
        </Flex>
        {/* <MenuBtn src={menuBtn3}/> */}
      </StyledTopFixedBar>

      {dayListLength > 1 && (
        <SelectBar ref={refSelectBar}>
          {dayList?.map((item: number, index: number) => (
            <H1
              key={index.toString()}
              id={item.toString()}
              data-index={index.toString()}
              onClick={onClickDay}
              left={`${initial + index * interval}%`}
              active={currentIndex === index}
            >
              {`${item}일차`}
            </H1>
          ))}
          <MovingBar left={pos} />
        </SelectBar>
      )}
    </TopFixedBlock>
  );
}

const StyledTopFixedBar = styled(TopFixedBar)`
  position: relative;
  background-color: white;
  border-bottom: 4px solid #ededf0;
`;

const H1 = styled.h1<{ left: string; active: boolean }>`
  position: absolute;
  left: ${props => props.left};
  top: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  font-size: 16px;
  font-style: normal;
  ${props =>
    props.active
      ? 'color: #111; font-weight: 600;'
      : 'color: var(--Font-04_Gray, #767676); font-weight: 400;'}

  line-height: 26px; /* 150% */

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
