import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState } from 'react';
import grayBlank from 'src/assets/images/user-mobile/GrayBlank.png';
import { IMiddleBanner } from 'src/interfaces/user-mobile/main.interface';
import styled, { useTheme } from 'styled-components';
import Slider from 'react-slick';
import {
  SliderImgBoard,
  SliderIndexDotHome,
  SliderIndexHome,
  StyledSliderBoardHome,
} from 'src/styles/user-mobile/CustomSliderStyle';
import onClickLink from 'src/utils/onClickLink';
import { useNavigate } from 'react-router-dom';

interface IProps {
  imageBoardInfo: IMiddleBanner[];
  // 여기 '?'는 있든 말든 상관 없다는 뜻. 인터페이스를 같이 쓸 때 활용
}

function CustomSliderMiddleBanner({ imageBoardInfo }: IProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentNum, setCurrentNum] = useState(1);

  const settings = {
    dots: false, // 슬라이드 바닥에 점을 보이게 할 것인지 설정
    arrows: false,
    infinite: true, // 무한 반복되게 할 것인지 설정
    speed: 300, // 슬라이드하는데 걸리는 시간 설정
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 개수
    slidesToScroll: 1, // 슬라이드 넘어갈 때마다 몇 개의 슬라이드를 넘길 것인지 설정
    autoplay: true, // 자동으로 슬라이드를 넘길 것인지 설정

    afterChange: (current: number) => {
      // event처럼 이미 current자리는 현재 슬라이드 번호에 대한 인자임
      setCurrentNum(current + 1);
    }, // 현재 슬라이드 위치에 따른 변화 get가능
  };

  return (
    <StyledSliderBoardHome height={88}>
      {imageBoardInfo?.length > 1 ? (
        <StyledSlider {...settings} imageBoardInfo={imageBoardInfo}>
          {imageBoardInfo?.map(
            // imagesInfo가 interface에서 '?'표시가 되어있기 때문에, 안전하지 않음.
            // 때문에 imagesInfo에도 '?'추가
            (item: IMiddleBanner, index: number) => (
              // const id = props.imagesInfo?.ids?[index];
              // map함수 안에서는 변수 선언 불가
              /* '?.'(옵셔널 체이닝)과 '?='가 한 묶음. '?[]'연산자는 아직 없음 */
              <StyledSliderImgBoard
                key={index.toString()}
                onClick={event => onClickLink(event, navigate)}
                data-url={item.url}
                src={item.image || grayBlank}
              />
            ),
          )}
        </StyledSlider>
      ) : (
        imageBoardInfo?.length === 1 && (
          <StyledSliderImgBoard
            onClick={event => onClickLink(event, navigate)}
            data-url={imageBoardInfo[0]?.url}
            src={imageBoardInfo[0]?.image || grayBlank}
          />
        )
      )}

      {imageBoardInfo?.length !== 0 && (
        <SliderIndexHome>
          {imageBoardInfo?.map((item: IMiddleBanner, index: number) => (
            <SliderIndexDotHome
              key={index.toString()}
              active={index === currentNum - 1}
              activeColor={theme.colors.fontColor}
            />
          ))}
        </SliderIndexHome>
      )}
    </StyledSliderBoardHome>
  );
}

export default CustomSliderMiddleBanner;

const StyledSlider = styled(Slider)<IProps>`
  background-color: transparent;
  border-radius: 0px 0px 12px 12px;
  overflow-y: hidden;
`;

const StyledSliderImgBoard = styled(SliderImgBoard)`
  height: 88px;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
  cursor: pointer;
`;
